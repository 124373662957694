import { Container } from '@mui/material'
import { FlexGrowCol } from '@xylabs/sdk-react'
import { SectionSpacingRow } from '@xyo-network/react-shared'

import { BasicHero } from '../../common'
import BasePage from '../BasePage'
import { PrivacyPolicyPages } from './img'

const TermsPage: React.FC = () => {
  return (
    <BasePage name="Privacy">
      <BasicHero title="Privacy Policy" desc="" />
      <SectionSpacingRow>
        <Container>
          <FlexGrowCol>
            {PrivacyPolicyPages.map((src, index) => (
              <img key={index} src={src} alt={`Privacy Policy Page ${index + 1}`} width="100%" />
            ))}
          </FlexGrowCol>
        </Container>
      </SectionSpacingRow>
    </BasePage>
  )
}

export default TermsPage
