import { default as SaleTerms1 } from './Terms_of_Sale_062121-01.png'
import { default as SaleTerms2 } from './Terms_of_Sale_062121-02.png'
import { default as SaleTerms3 } from './Terms_of_Sale_062121-03.png'
import { default as SaleTerms4 } from './Terms_of_Sale_062121-04.png'
import { default as SaleTerms5 } from './Terms_of_Sale_062121-05.png'
import { default as SaleTerms6 } from './Terms_of_Sale_062121-06.png'
import { default as SaleTerms7 } from './Terms_of_Sale_062121-07.png'
import { default as SaleTerms8 } from './Terms_of_Sale_062121-08.png'
import { default as SaleTerms9 } from './Terms_of_Sale_062121-09.png'
import { default as SaleTerms10 } from './Terms_of_Sale_062121-10.png'
import { default as SaleTerms11 } from './Terms_of_Sale_062121-11.png'
import { default as SaleTerms12 } from './Terms_of_Sale_062121-12.png'
import { default as SaleTerms13 } from './Terms_of_Sale_062121-13.png'
import { default as SaleTerms14 } from './Terms_of_Sale_062121-14.png'
import { default as SaleTerms15 } from './Terms_of_Sale_062121-15.png'
import { default as SaleTerms16 } from './Terms_of_Sale_062121-16.png'
import { default as SaleTerms17 } from './Terms_of_Sale_062121-17.png'
import { default as SaleTerms18 } from './Terms_of_Sale_062121-18.png'
import { default as SaleTerms19 } from './Terms_of_Sale_062121-19.png'

export const SalesTermsPages = [
  SaleTerms1,
  SaleTerms2,
  SaleTerms3,
  SaleTerms4,
  SaleTerms5,
  SaleTerms6,
  SaleTerms7,
  SaleTerms8,
  SaleTerms9,
  SaleTerms10,
  SaleTerms11,
  SaleTerms12,
  SaleTerms13,
  SaleTerms14,
  SaleTerms15,
  SaleTerms16,
  SaleTerms17,
  SaleTerms18,
  SaleTerms19,
]
